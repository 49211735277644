import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for React 18
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import { MortgageGuideRoutes } from 'GenericMortgageGuides';
import Footer from './components/FooterNew/index';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookieSetting } from "cookiesetting-component";

const location = window.location.pathname.toLowerCase();
const baseName = process.env.REACT_APP_BASENAME;
let basePath = baseName === "/conventional-loans" ? `${baseName}/` : baseName;

// Create root instance for React 18
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {location === baseName || location === "/conventional-loans/" ? <App /> : null}
      {location !== baseName && location !== "/conventional-loans/" ? (
        <MortgageGuideRoutes
          homePath={basePath}
          showHeader={true}
          showFooter={true}
          FooterComponent={Footer}
        />
      ) : null}
      <CookieSetting 
        privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
        configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
        analytics={process.env.REACT_APP_ANALYTICS_PATH}
      />
    </BrowserRouter>
  </React.StrictMode>
);

// Measure performance if needed
reportWebVitals();
